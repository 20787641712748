<template>
  <jaya-connection-layout text="Une mise à jour est nécessaire !">
    <b-button @click="getAppLink" class="col-12 mb-2">
      Mettre à jour l'application
    </b-button>
  </jaya-connection-layout>
</template>

<script>
import JayaConnectionLayout from "@/components/global/JayaConnectionLayout";
import {BButton, BCol, BRow} from "bootstrap-vue";

export default {
  components: {
    JayaConnectionLayout,
    BRow,
    BCol,
    BButton,
  },
  methods: {
    getAppLink() {
      let appLink = window.cordova && window.cordova.platformId === "android" ? 'https://play.google.com/store/apps/details?id=fr.jaya.hortilio' : 'https://apps.apple.com/fr/app/hortilio/id1539173291'
      window.cordova.InAppBrowser.open(appLink, '_system')
    }
  }
}
</script>
